import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import GlobalStyles from "@mui/material/GlobalStyles";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Iframe from "react-iframe";
import sala1 from "./renders/sala1.jpg"
import sala2 from "./renders/sala2.jpg";
import sala3 from "./renders/sala3.jpg";
import sala4 from "./renders/sala4.jpg";
import toaleta from "./renders/toaleta.jpg";
import recepcja from "./renders/recepcja.jpg";
import rzut from "./renders/rzut.jpg";
import { sizing } from '@mui/system';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>

      <GlobalStyles

      />
      <CssBaseline />
      <Container

        sx={{

          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
        maxWidth="lg"
        minHeight="100%"
      >
        <div>
          <br /><br />
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"

          >
            <Tabs

              // variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"

            >
              <Tab label="Opis Lokalu" {...a11yProps(0)} />
              <Tab label="Rzut lokalu" {...a11yProps(1)} />
              <Tab label="Spacer po lokalu" {...a11yProps(2)} />
              <Tab label="Sala nr 1" {...a11yProps(3)} />
              <Tab label="Sala nr 2" {...a11yProps(4)} />
              <Tab label="Sala nr 3" {...a11yProps(5)} />
              <Tab label="Sala nr 4" {...a11yProps(6)} />
              <Tab label="Recepcja" {...a11yProps(7)} />
              <Tab label="Toaleta" {...a11yProps(8)} />
            </Tabs>
            <br /><br />
            <TabPanel value={value} index={0}>
               
                <Typography
                  variant="h7"
                  align="justify"
                  color="text.secondary"
                  component="p"
                >
                  Podziemia kamienicy przy Rynku Głównym w Krakowie stanowią wyjątkowe miejsce o niezwykłej historii i unikatowym charakterze. Oferują one przestronny lokal o powierzchni 160 metrów kwadratowych, lokal objęty jest zakazem prowadzenia działalności gastronomicznej.
                  <br /><br />
                  Wchodząc do tego magicznego podziemia, natychmiast ogarnia nas aura tajemniczości i historii, które unoszą się w powietrzu. Przestrzeń urzeka swoją elegancją, zachowując jednocześnie autentyczny klimat dawnych czasów. Światło, płynące z umiejętnie rozmieszczonych źródeł oświetlenia galeryjnego, podkreśla detale i atmosferę tego wyjątkowego miejsca.
                  <br /><br />
                  Lokal jest funkcjonalny i komfortowy. Osobne wejście zapewnia prywatność oraz kontrolę nad dosepem lokalu dla osób postronnych. Układ pomieszczeń pozwala na zorganiozowanie wydarzeń o zróżnicowanym charakterze.
                  <br /><br />
                  Miejsce to wyróżnia nie tylko lokalizacja, ale także wyjątkowy charakterm i historia. Podziemia kamienicy 'Pod Słońcem' położonej na Rynku Głównym w Krakowie stanowią wyjątkową ofertę dla tych, którzy poszukują interesującej przestrzeni o bogatej historii.
                </Typography>
            </TabPanel>
            <TabPanel value={value} index={1}>
               
                <img src={rzut} alt=""  width="100%" loading="lazy" />
              </TabPanel>

            <TabPanel value={value} index={2}>
               
                <iframe width="649" height="360" src="https://www.youtube.com/embed/WW5cSWmvfyk" title="Piwnica Rynek Główny - najem" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </TabPanel>
            <TabPanel value={value} index={3}>
              Sala 1 o piwerzchni xx oraz wysposażenie
              <hr/>
              <br />
              <img src={sala1} alt=""  width="100%" loading="lazy" /> 
            </TabPanel>
            <TabPanel value={value} index={4}> 
              Sala 2 o piwerzchni xx oraz wysposażenień
              <hr/>
              <br />
              <img src={sala2} alt=""  width="100%" loading="lazy" />
             </TabPanel>
            <TabPanel value={value} index={5}> 
              Sala 3 o piwerzchni xx oraz wysposażenie
              <hr/>
              <br /> <img src={sala3} alt=""  width="100%" loading="lazy" />
             </TabPanel>
            <TabPanel value={value} index={6}> 
              Sala 4 o piwerzchni xx oraz wysposażenie
              <hr/>
              <br /> <img src={sala4} alt=""  width="100%" loading="lazy" />
             </TabPanel>
            <TabPanel value={value} index={7}> 
              Recepcja o piwerzchni xx oraz wysposażenie
              <hr/>
              <br /> <img src={recepcja} alt=""  width="100%" loading="lazy" />
     </TabPanel>
            <TabPanel value={value} index={8}> 
              Toaleta o piwerzchni xx oraz wysposażenie
              <hr/>
              <br /> <img src={toaleta} alt=""  width="100%" loading="lazy" />
              </TabPanel>
          </Grid>
        </div>




      </Container>
      <br /><br /><br /><br />
      <Container

sx={{

  display: {
    xs: "block",
    sm: "block",
    md: "none",
    lg: "none",
    xl: "none",
  },
}}
maxWidth="sm"
>


<Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="h5"
        >
         Informacje o lokalu </Typography>
       <br/>
        <Typography
          variant="h7"
          align="justify"
          color="text.secondary"
          component="p"
        >
          Podziemia kamienicy przy Rynku Głównym w Krakowie stanowią wyjątkowe miejsce o niezwykłej historii i unikatowym charakterze. Oferują one przestronny lokal o powierzchni 160 metrów kwadratowych, lokal objęty jest zakazem prowadzenia działalności gastronomicznej.
          <br /><br />
          Wchodząc do tego magicznego podziemia, natychmiast ogarnia nas aura tajemniczości i historii, które unoszą się w powietrzu. Przestrzeń urzeka swoją elegancją, zachowując jednocześnie autentyczny klimat dawnych czasów. Światło, płynące z umiejętnie rozmieszczonych źródeł oświetlenia galeryjnego, podkreśla detale i atmosferę tego wyjątkowego miejsca.
          <br /><br />
          Lokal jest funkcjonalny i komfortowy. Osobne wejście zapewnia prywatność oraz kontrolę nad dosepem lokalu dla osób postronnych. Układ pomieszczeń pozwala na zorganiozowanie wydarzeń o zróżnicowanym charakterze.
          <br /><br />
          Miejsce to wyróżnia nie tylko lokalizacja, ale także wyjątkowy charakterm i historia. Podziemia tej kamienicy przy Rynku Głównym w Krakowie stanowią wyjątkową ofertę dla tych, którzy poszukują interesującej przestrzeni o bogatej historii.
        </Typography>
    
        Rzut lokalu
        <hr/>
        <img src="https://lh5.googleusercontent.com/7pYOru2GbqfvbXflhOprUQlUbd_pNR10P8VqupX0gBO1-GKX4HFsKQ04V70C3ayfWpGP0vIn9_Jn5AnXrCBOyFEKmnnwei5jDMU7FNIYhqbm9g6mVmWVXvOqwjcLZCId9g=w1280" alt=""  width="100%" loading="lazy" />
     
        Spacer po lokalu
        <hr/>
        <iframe width="480" height="240" src="https://www.youtube.com/embed/WW5cSWmvfyk" title="Piwnica Rynek Główny - najem" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <br /><br />
      Sala 1 o piwerzchni xx oraz wysposażenie
      <hr/>
       
      <img src={sala1} alt=""  width="100%" loading="lazy" /> 
      <br />
      Sala 2 o piwerzchni xx oraz wysposażenie
      <hr/>
      <br /><br /> 
      <img src={sala2} alt=""  width="100%" loading="lazy" /> 
   
      <br /><br />
      Sala 3 o piwerzchni xx oraz wysposażenie
      <hr/>
      <br />  <img src={sala3} alt=""  width="100%" loading="lazy" /> 
      <br /><br />
      Sala 4 o piwerzchni xx oraz wysposażenie
      <hr/>
      <br />  <img src={sala4} alt=""  width="100%" loading="lazy" /> 
      <br /><br />
      Recepcja o piwerzchni xx oraz wysposażenie
      <hr/>
      <br />  <img src={recepcja} alt=""  width="100%" loading="lazy" /> 

      <br /><br />
      Toaleta o piwerzchni xx oraz wysposażenie
      <hr/>
      <br />  <img src={toaleta} alt=""  width="100%" loading="lazy" /> 
      
  




</Container>

    </React.Fragment>
  );
}
