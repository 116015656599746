import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/icons-material/Grading";
import Com from "@mui/icons-material/ConnectWithoutContact";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import BorderClearIcon from "@mui/icons-material/BorderClear";
import { Helmet } from "react-helmet";
const text = [
  {
    one:'',
    two:'',
    three:'',
    four:'',
    five:'',
  },
];

function PricingContent() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <main>

        <Container maxWidth="lg">


          <Box
            sx={{

              minHeight: "80vh",
            }}
          >
            <Container
              data-aos="fade-down"
              sx={{
                pt: 5,
                pb: 22,
                // backgroundColor: "secondary.main",
              }}
            >
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color=""
                gutterBottom
                sx={{
                  backgroundColor: "primary",
                }}
              >
                Lokalizacja
                <hr />
              </Typography>
              <Grid container spacing={0} alignItems="">
                {text.map((tier) => (
                  <Grid item key={tier.title} xs={12} md={12}>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.one}
                        id={tier.one}
                      >
                        {" "}
                        <ListItemIcon>
                          <List fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
                          Wejście do lokalu

                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails align="justify">
                        <Typography>{tier.one}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.two}
                        id={tier.two}
                      >
                        {/* <img src={one} width="10%" /> */}
                        <ListItemIcon>
                          <Com fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
                          Gdzie zaparkować                    </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="justify">{tier.two}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.three}
                        id={tier.three}
                      >
                        <ListItemIcon>
                          <AccessTimeIcon fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
                          Komunikacja miejska
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="justify">{tier.three}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel4"}
                      onChange={handleChange("panel4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.four}
                        id={tier.four}
                      >
                        <ListItemIcon>
                          <AccessibilityNewIcon fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
                          Sąsiednie atrakcje                      </Typography>
                      </AccordionSummary>
                      <AccordionDetails align="justify">
                        <Typography>{tier.four}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel5"}
                      onChange={handleChange("panel5")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.five}
                        id={tier.five}
                      >
                        {" "}
                        <ListItemIcon>
                          <BorderClearIcon fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
                          gdzie jeść gdzie spać??                  </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="justify">{tier.five}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
          <Helmet>
            <title>Lokalizacja piwnicy</title>
            <meta name="description" content="Lokalizacji piwnicy, jak dojechać do piwnicy pod słońcem" />
            <link
              rel="canonical"
              href="https://artstrefa43.com/Lokalizacja"
            />
          </Helmet>
        </Container>
      </main>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
