import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Iframe from "react-iframe";
import emailjs from "@emailjs/browser";
import TextField from "@mui/material/TextField";
import Cookies from "./cookies.jsx";
import NavPc from "./navPc";
import GlobalStyles from "@mui/material/GlobalStyles";
import AOS from "aos";
import "aos/dist/aos.css";
import {Helmet} from "react-helmet";
function Copyright() {
  return (
    <Typography variant="body2" color="primary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://cholujdesign.com/">
        CholujDesign S.C.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
function sendEmail(e) {
  e.preventDefault();

  emailjs
    .sendForm(
      "service_a1wwmva",
      "template_jnm4g0d",
      e.target,
      "user_x9kPpW1JmhYVbwVWmxAVD"
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
}

const theme = createTheme({
  palette: {
    primary: {
      light: "#f5f5f5",
      main: "#825b07",
      dark: "#825b07",
      contrastText: "#000",
    },
    secondary: {
      light: "#022911",
      main: "#022911",
      dark: "#022911",
      contrastText: "#fff",
    },
  },
  shadows: ["none"],
  typography: {
    fontFamily: ["Overlock"].join(","), fontWeight: 500, letterSpacing: 2,
    textTransform: 'none',
  },
});

export default function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <React.Fragment>
     <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
        <CssBaseline />
        
    <main>
    <Helmet>
        
        <title>
        Kontakt
        </title>
        <meta name="description" content="Kontakt"
        />
         <meta name="keywords" content="Piwnica pod Słońcem, kontakt"/>
        <link rel="canonical" href="https://artstrefa43.com/Kontakt"/>
      </Helmet>
        {/* <Box>
             <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                  <Typography
          
              component="h1"
              variant="h1"
              align="center"
              color=""
             
            >
              KMT
            </Typography>
             <Typography
            
              component="h6"
              variant="h6"
              align="center"
              color=""
            
            >
              Kozubek
            Matusiak
            Truszkiewicz
                <hr/>
            </Typography>
         
          
                <Button
                    size="large"
                    href="https://goo.gl/maps/PiQzy4NwX1Y7FHC9A"
                    color="secondary"
                    variant=""
                  >
                    Piwna 15,
                 
                     30-527 Kraków, 
                     
                     Polska
                     
                  </Button>
                
                  <Button size="large" color="secondary" variant="">
                    +48 123 123 123
                  </Button>
                
                  <Button size="large" color="secondary" variant="">
                    biuro@kmtlegal.pl
                  </Button>
                  <br/>
                <Box
                  sx={{
                  }}
                  component="form"
                  onSubmit={sendEmail}
                  noValidate
                >
                    <br/>
                     <Typography color="" component="h2" variant="h5">
                  Formularz kontaktowy
                </Typography>
              
                <Grid container spacing={2} sx={{p:2}}>
                <Grid
              item
              key="2f4"
              xs={12}
              sm={12}
              md={12}
              
            >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Twój Email"
                    name="user_email"
                    autoComplete="email"
                  />
   </Grid>  <Grid
              item
              key="2f4"
              xs={12}
              sm={12}
              md={6}
              
            >
                  <TextField
                    margin="normal"
                   
                    fullWidth
                    id="name"
                    label="Imię i nazwisko "
                    name="user_name"
                    autoComplete="name"
                  />
                   </Grid>  <Grid
              item
              key="2f4"
              xs={12}
              sm={12}
              md={6}
              
            >
                  <TextField
                    multiline
                    margin="normal"
                    
                    fullWidth
                    name="number"
                    label="Numer telefonu"
                    type="number"
                    id="number"
                  /></Grid>
                    <Grid
              item
              key="2ff4"
              xs={12}
              sm={12}
              md={12}
              
            >
                  <TextField
                    multiline
                    margin="normal"
                    required
                    fullWidth
                    name="message"
                    label="Wiadomość"
                    type="text"
                    id="text"
                    rows={4}
                  />
                  * Pola obowiązkowe.
                  <br/>
                  
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Wyślij wiadomość
                  </Button></Grid></Grid>
                </Box>
              </Box>    
          <br />
         
          </Box> */}
         
        {/* End footer */}
        </main>
        
        </React.Fragment>
  );
}
