import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/icons-material/Grading";
import Com from "@mui/icons-material/ConnectWithoutContact";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import BorderClearIcon from "@mui/icons-material/BorderClear";
import { Helmet } from "react-helmet";


function PricingContent() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <main>

        <Container maxWidth="lg">
          {/* <Box
            sx={{
  
              pt: 3,
              pb: 3,
              minHeight: "20vh",
            }}
          >
            <Container
              data-aos="fade-up"
              disableGutters
              component="main"
              sx={{
                pt: 0,
                pb: 0,
            
              }}
            >
              <Container
                sx={{
                  m: 0.2,
                  py: 5,
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
              ></Container>

              <Typography
                variant="h5"
                align="justify"
                color=""
                component="p"
                gutterBottom
              >
                <br />
                KMT Kozubek Matusiak. We are a law firm created to provide comprehensive business support so that entrepreneurs may focus fully on their own business. We are a trusted legal partner to our Clients, carefully listening to their needs in order to deliver the best solutions.  </Typography>
            </Container>
          </Box> */}

          <Box
            sx={{

              minHeight: "80vh",
            }}
          >
            <Container
              data-aos="fade-down"
              sx={{
                pt: 5,
                pb: 22,
                // backgroundColor: "secondary.main",
              }}
            >
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color=""
                gutterBottom
                sx={{
                  backgroundColor: "primary",
                }}
              >
                Często zadawane pytania
                <hr />
              </Typography>
              <Grid container spacing={0} alignItems="">

                <Grid item key="1" xs={12} md={12}>
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="1"
                      id="1"
                    >
                      {" "}
                      <ListItemIcon>
                        <List fontSize="large" />
                      </ListItemIcon>
                      <Typography
                        component="h5"
                        variant="h5"
                        align="justify"
                        color=""
                        gutterBottom
                      >
                        Czy można wynająć lokal na cele prowadzenia gastronomii?

                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails align="justify">
                      <Typography>
                        Niestety nie można prowadzić na terenie lokalu działanosći gastronommicznej.
                        <br />
                        W celu zapewnienia cateringu współpracujemy z Kawiarnią Bankowa.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="2"
                      id="2"
                    >
                      {/* <img src={one} width="10%" /> */}
                      <ListItemIcon>
                        <Com fontSize="large" />
                      </ListItemIcon>
                      <Typography
                        component="h5"
                        variant="h5"
                        align="justify"
                        color=""
                        gutterBottom
                      >
                        Czy lokal jest przystosowany do pokazywania sztuki?                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography align="justify">
                        Tak. W lokalu znajduje się oświetlenie przystosowane do pokazywania dzieł sztuki, oraz system do zawieszania obrazów, zdjęć, plakatów.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="3"
                      id="3"
                    >
                      <ListItemIcon>
                        <AccessTimeIcon fontSize="large" />
                      </ListItemIcon>
                      <Typography
                        component="h5"
                        variant="h5"
                        align="justify"
                        color=""
                        gutterBottom
                      >
                        Czy lokal przystosowany jest do prowadzenia wykładów, przemówień.
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography align="justify">
                        Tak.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="4"
                      id="4"
                    >
                      <ListItemIcon>
                        <AccessibilityNewIcon fontSize="large" />
                      </ListItemIcon>
                      <Typography
                        component="h5"
                        variant="h5"
                        align="justify"
                        color=""
                        gutterBottom
                      >
                        Czy lokal jest przystosowany dla osób niepełnosprawnych?                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails align="justify">
                      <Typography>
                        Niestety nie. Do lokalu prowadzą schody.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="5"
                      id="5"
                    >
                      {" "}
                      <ListItemIcon>
                        <BorderClearIcon fontSize="large" />
                      </ListItemIcon>
                      <Typography
                        component="h5"
                        variant="h5"
                        align="justify"
                        color=""
                        gutterBottom
                      >
                        Czy w lokalu znadują się recepcja i toaleta                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography align="justify">Tak. Recepcja, która słóżyła wcześniej  jako bar znajuje się bezpośrednio przy wejsćiu do lokalu.<br />
                        Toaleta posiada  kabiny dla Pań i Panów. </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel6"}
                    onChange={handleChange("panel6")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="6"
                      id="6"
                    >
                      {" "}
                      <ListItemIcon>
                        <BorderClearIcon fontSize="large" />
                      </ListItemIcon>
                      <Typography
                        component="h5"
                        variant="h5"
                        align="justify"
                        color=""
                        gutterBottom
                      >
                        Czy lokal przystosowany jest do celów wystawienniczych?                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography align="justify">Tak. Natomiast należy mieć na uwadze to, że do lokalu prowadzi klatka schodowa o szerokości około 1.2 m.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel7"}
                    onChange={handleChange("panel7")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="7"
                      id="7"
                    >
                      {" "}
                      <ListItemIcon>
                        <BorderClearIcon fontSize="large" />
                      </ListItemIcon>
                      <Typography
                        component="h5"
                        variant="h5"
                        align="justify"
                        color=""
                        gutterBottom
                      >
                        Czy lokal jest zamykany i posiada zabezpieczenia?                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography align="justify">Tak. Lokal posiada osobne wejscie i drzwi oraz zabezpieczony jest alarmem antywłamaniowym.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel8"}
                    onChange={handleChange("panel8")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="8"
                      id="8"
                    >
                      {" "}
                      <ListItemIcon>
                        <BorderClearIcon fontSize="large" />
                      </ListItemIcon>
                      <Typography
                        component="h5"
                        variant="h5"
                        align="justify"
                        color=""
                        gutterBottom
                      >
                        Czy wystawuają Panstwo fakturę VAT?                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography align="justify">Tak.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

              </Grid>
            </Container>
          </Box>
          <Helmet>
            <title>FAQ</title>
            <meta name="description" content="Często zadawane pytania" />
            <link
              rel="canonical"
              href="https://artstrefa43.com/FAQ"
            />
          </Helmet>
        </Container>
      </main>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
