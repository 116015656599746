import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Iframe from "react-iframe";
import emailjs from "@emailjs/browser";
import TextField from "@mui/material/TextField";
import Cookies from "./cookies.jsx";
import logo from "./renders/logo.png";
import InfoIcon from "@mui/icons-material/Info";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import Alert from "@mui/material/Alert";

function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      <br />
      Strona wykorzystuje pliki cookies. W ramach naszej witryny stosujemy pliki
      cookies w celu świadczenia Państwu usług na najwyższym poziomie, w tym w
      sposób dostosowany do indywidualnych potrzeb. Korzystanie z witryny bez
      zmiany ustawień dotyczących cookies oznacza, że będą one zamieszczane w
      Państwa urządzeniu końcowym. Możecie Państwo dokonać w każdym czasie
      zmiany ustawień dotyczących cookies.
      <br />
      <Link color="inherit" href="/cookies">
        Naciśnij po więcej szczegółów o Polityce Prywatności i Cookies.
      </Link>{" "}
      <br />
      <Link color="inherit" href="/AML-RODO">
        Naciśnij po więcej szczegółów o AML-RODO.
      </Link>{" "}
      <hr />
      {
        "Copyright © Choluj Design s.c."
      }{" "}
      {new Date().getFullYear()}
      <br />
      <Link color="inherit" href="https://cholujdesign.com/">
        Wykonanie strony: CholujDesign S.C.
      </Link>{" "}
      {"."}
    </Typography>
  );
}

function refreshPage() {
  window.location.reload(false);
}

const theme = createTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#825b07",
      dark: "#825b07",
      contrastText: "#000",
    },
    secondary: {
      light: "#291002",
      main: "#291002",
      dark: "#291002",
      contrastText: "#fff",
    },
  },
  shadows: ["none"],
  typography: {
    fontFamily: ["Overlock"].join(","), fontWeight: 500, letterSpacing: 2,
    textTransform: "none",
    button: {
      textTransform: "none",
    },
  },
});

export default function App() {
  const [wyslanoWiadomosc, setwyslanoWiadomosc] = React.useState(false);
  const [validated, setValidated] = React.useState(false);
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_a1wwmva",
      "template_jnm4g0d",
      e.target,
      "user_x9kPpW1JmhYVbwVWmxAVD"
      )
      .then(
        (result) => {
          console.log(result.text);
          setwyslanoWiadomosc(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  
  const Wyslano = () => {
    return (
      <div>
        {wyslanoWiadomosc ? (
          <Alert
            sx
            // action={
            //   <Button onClick={refreshPage} color="inherit" size="small">
            //     X
            //   </Button>
            // }
          >
            Wiadomość wysłana!
          </Alert>
        ) : null}
      </div>
    );
  };
//   if (localStorage.getItem('lang') !== 'eng'){
//     return (<Footer/>)}
//  else { 
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        {/* Footer */}
        <Box sx={{ bgcolor: "primary.light", mt: 0, p: 0 }} component="footer">
          <Grid container spacing={0}>
            <Grid
              item
              key="24"
              xs={12}
              sm={12}
              md={12}
              // data-aos="fade-left"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
                width: "100%",
                flexDirection: "column",
              }}
            >
              <Iframe
                url = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d447.30624659058566!2d19.93828461867045!3d50.06238505810088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b0e1dd33297%3A0xa8c90fce36f5f86b!2sCho%C5%82uj%20Design%20s.c.%20Cho%C5%82uj%20I.%2C%20Cho%C5%82uj%20R.!5e1!3m2!1spl!2spl!4v1730221949557!5m2!1spl!2spl" 
                width="100%"
                height="690px"
                id="myId"
                className="iframe"
                //display="block"
                // display="initial"
                position="relative"
              />{" "}
            </Grid>
            <Grid
              item
              key="324"
              xs={12}
              sm={6}
              md={6}
              data-aos="fade-left"
              sx={{
                height: "100%",
                align: "left",
                alignItems: "left",
                pl: 2,
                // display: "flex-end",
                // flexDirection: "row",
              }}
            >
              <Typography align="left" color="" component="h1" variant="h5">
                <br />
                <img src={logo} width="100" />
                <br />
                {/* <Button
                    size="large"
                    href="https://goo.gl/maps/3RvXifFCKNKv1XgE8"
                    color="secondary"
                    variant=""
                  > */}
               Piwnica Kamienicy  <br />
               Pod Słońcem
                {/* </Button> */}
                <br />
                <br />
                <div className="samllText" align="left">
                  ul. Rynek Główny 43
                  <br /> 31-013 Kraków, Polska
                  <Button
                    size="large"
                    href="https://goo.gl/maps/3RvXifFCKNKv1XgE8"
                    color="secondary"
                    variant=""
                    sx={{
                      "&:hover": {
                        backgroundColor: "#333",
                      },
                    }}
                  >
                    {" "}
                    <AddLocationAltIcon />
                  </Button>
                  {/* <br />
                  +48 690 720 320
                  <Button
                    size="large"
                    href="tel:+48690720320"
                    color="secondary"
                    variant=""
                    sx={{
                      "&:hover": {
                        backgroundColor: "#333",
                      },
                    }}
                  >
                    <CallIcon />
                  </Button> */}
                  <br />
                  biuro@r43.pl
                  <Button
                    size="large"
                    href="mailto:biuro@r43.pl"
                    color="secondary"
                    variant=""
                    sx={{
                      "&:hover": {
                        backgroundColor: "#333",
                      },
                    }}
                  >
                    <EmailIcon />
                  </Button>
                  <br /> <br />
                  NIP: 6762437655
                
                </div>
             
                <br />
              </Typography>
            </Grid>
            <Grid
              item
              key="34"
              xs={12}
              sm={6}
              md={6}
              data-aos="fade-left"
              sx={{
                height: "100%",
                display: "flex-end",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{}} component="form" onSubmit={sendEmail} noValidate={validated}>
                  <br />
                  <Typography color="" component="h2" variant="h5">
                    Formularz kontaktowy
                  </Typography>

                  <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid
                      item
                      key="2f4"
                      xs={12}
                      sm={12}
                      md={12}
                      data-aos="fade-up"
                    >
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="from_email"
                        autoComplete="email"
                      />
                    </Grid>{" "}
                    
                    <Grid
                      item
                      key="2ff4"
                      xs={12}
                      sm={12}
                      md={12}
                      data-aos="fade-up"
                    >
                      <TextField
                        multiline
                        margin="normal"
                        required
                        fullWidth
                        name="message"
                        label="Wiadomość"
                        type="text"
                        id="text"
                        rows={4}
                      />
                      * Pola obowiązkowe.
                      <br />
                      <Wyslano />
                      <Button
                        
                        type="submit"
                        color="secondary"
                        variant="contained"
                        large
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Wyślij wiadomość
                      </Button>
                      <br />
                      <p className="samllText" align="justify">
                        Administratorem danych osobowych jest Choluj Design spółka cywilna,
                        adres: ul. Rynek Główny 43, 31-013 Kraków; NIP: 6762437655
    . Dane osobowe są przetwarzane w celu
                        obsługi zapytania, w szczególności prowadzenia
                        korespondencji w tym zakresie, a także w celu ustalenia,
                        dochodzenia lub obrony przed potencjalnymi roszczeniami.
                        Więcej informacji na temat przetwarzania danych
                        osobowych znajduje się w Polityce Prywatności i Cookies,
                        do których link znajduje się poniżej.
                      </p>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <br />
        </Box>
        <Box sx={{ backgroundColor: "secondary.main" }}>
          <br />
          <br />
          <Copyright />
          <br />
          <br />
        </Box>

        {/* End footer */}
      </main>
    </ThemeProvider>
  );
// }
}
